import * as Yup from "yup";
export const validationSchema = Yup.object().shape({
  tags: Yup.array()
    .of(
      Yup.string()
        .matches(
          /^[a-zA-Z0-9\s]+$/,
          "Tags can only contain letters, numbers, and spaces"
        )
        .required("Tag is required")
    )
    .max(5, "You can only add up to 5 tags")
    .notRequired(),
  country: Yup.string().required("Countries Is Required"),
  city: Yup.string().required("Cities Is Required"),
  job_company_industry: Yup.string().required(
    "Job Company Industry Is Required"
  ),
  job_company_industry_other: Yup.mixed().when("job_company_industry", {
    is: "Other",
    then: () => Yup.string().required("Job Company Industry Is Required"),
  }),
  job_company_type: Yup.string().required("Job Company Type Is Required"),
  job_title: Yup.string()
    .matches(
      /^[A-Za-z0-9\s,.\-&() \n]*$/,
      "Job title can only contain letters, numbers, spaces, commas (,), periods (.), hyphens (-), parentheses (()), and ampersands (&)."
    )
    .min(3, "Minimum chars is 3")
    .max(100, "Maximum chars is 100")
    .required("Job Title Is Required"),
  job_role: Yup.string()
    .matches(
      /^[A-Za-z0-9\s,.\-&() \n]*$/,
      "Job role can only contain letters, numbers, spaces, commas (,), periods (.), hyphens (-), parentheses (()), and ampersands (&)."
    )
    .min(3, "Minimum chars is 3")
    .max(100, "Maximum chars is 100")
    .required("Job Role Is Required"),
  job_seniority_level: Yup.string().required("Job Seniority Level Is Required"),
  job_employmentType: Yup.string().required("Job Employment Is Required"),
  job_employmentTypeHours: Yup.mixed().when("job_employmentType", {
    is: "Working-hours",
    then: () =>
      Yup.number()
        .moreThan(0, "Please enter a valid number of hours")
        .required("Hours Is Required"),
  }),
  work_place_type: Yup.string().required("Work Place Type Is Required"),
  salary: Yup.string().required("Salary Is Required"),
  staticSalary: Yup.mixed().when("salary", {
    is: "static",
    then: () =>
      Yup.number()
        .moreThan(0, "Please enter a valid number of salary")
        .min(1000, "Minimum salary is 1000")
        .max(100000, "Maximum salary is 100000")
        .required("Salary Is Required"),
  }),
  vacancies: Yup.number()
    .min(1, " minimum value is 1")
    .max(45, " maximum value is 45")
    .required("Vacancies Is Required"),
  years_experience_from: Yup.number()
    .required("Years Experience From is required")
    .typeError("Must be a number")
    .min(0, "Minimum value is 0")
    .max(50, "Maximum value is 50"),
  years_experience_to: Yup.number()
    .min(0, "Minimum value is 0")
    .max(50, "Maximum value is 50")
    .required("Years Experience To is required")
    .when("years_experience_from", (years_experience_from, schema) =>
      schema.test(
        "is-greater-than-from",
        `Years Experience To must be greater than or equal to Years Experience From (${years_experience_from})`,
        (value) =>
          !value || !years_experience_from || value >= years_experience_from
      )
    ),
  description: Yup.string()
    .matches(
      /^[A-Za-z0-9\s,.\-&() \n]*$/,
      "Job description can only contain letters, numbers, spaces, commas (,), periods (.), hyphens (-), parentheses (()), and ampersands (&)."
    )
    .min(50, "At least 50 characters")
    .max(2000, "Less than 2000 required")
    .required("Description Is Required"),
  key_responsibilities: Yup.string()
    .matches(
      /^[A-Za-z0-9\s,.\-&() \n]*$/,
      "Key responsibilities can only contain letters, numbers, spaces, commas (,), periods (.), hyphens (-), parentheses (()), and ampersands (&)."
    )
    .min(50, "At least 50 characters")
    .max(2000, "Less than 2000 required")
    .required("Key Responsibilities Is Required"),
  qualifications: Yup.string()
    .matches(
      /^[A-Za-z0-9\s,.\-&() \n]*$/,
      "Qualifications can only contain letters, numbers, spaces, commas (,), periods (.), hyphens (-), parentheses (()), and ampersands (&)."
    )
    .min(50, "At least 50 characters")
    .max(2000, "Less than 2000 required")
    .required("Qualifications Is Required"),
});
