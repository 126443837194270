import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { generateSlug } from "../../../units/GenerateSlug";
import { useDispatch } from "react-redux";
import { toggleEditForm } from "../../../store/slices/editFormUISlice";
import Badge from "../../../Shared/Buttons/Badge/Badge";

const JobsCard = ({ job, idx, Swiper }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickOnCard = (jobId, jobName) => {
    navigate(`/job/${generateSlug(jobName)}?id=${jobId}`);
    dispatch(toggleEditForm(false));
  };
  return (
    <div
      className={`flex ${
        Swiper ? "items-start gap-4" : "items-center gap-3"
      } p-0 mb-4`}
    >
      <div
        className={`flex ${
          Swiper
            ? "w-full flex-row items-start gap-4"
            : " flex-col items-start p-0 gap-2 w-[55%]"
        }`}
      >
        {Swiper && (
          <h3 className="text-[#012E54] text-5xl font-semibold">
            {(idx + 1).toString().padStart(2, "0")}
          </h3>
        )}

        <div className="flex flex-col items-start">
          <div
            className={`flex flex-col items-start p-0 ${
              !Swiper ? "gap-[10px]" : "gap-0"
            } w-full`}
          >
            <h5 className="font-title font-semibold text-xs text-main">
              {job?.job_title}
            </h5>
            <h4
              onClick={() => handleClickOnCard(job?.id, job?.job_title)}
              className="cursor-pointer font-title font-semibold text-base text-submain overflow-hidden text-ellipsis whitespace-normal break-all max-h-[3rem]"
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
              }}
            >
              {job?.description}
            </h4>
            {!Swiper && (
              <div className="flex items-start p-0 gap-[10px] w-full h-[30px] max-sm:flex-row max-md:flex-col max-lg:flex-row max-xl:flex-col max-xl:gap-2 max-xl:mb-3">
                <Badge text={job?.job_employment?.type} />
                <Badge text={job?.work_place_type} />
              </div>
            )}
            <h6 className="cursor-pointer font-title font-semibold text-xs text-black">
              {job?.creator?.name}
            </h6>
          </div>
          <div
            onClick={() => handleClickOnCard(job?.id, job?.job_title)}
            className="flex items-center p-2 gap-2 w-[35px] h-[35px] bg-bgmain rounded-sm cursor-pointer transition-transform duration-300 hover:bg-hover hover:translate-y-[-2px]"
          >
            <i className="bi bi-arrow-right text-[18px] text-main flex items-center justify-center hover:text-white"></i>
          </div>
        </div>
      </div>

      {!Swiper && (
        <div
          onClick={() => dispatch(toggleEditForm(false))}
          className="w-[45%] border cursor-pointer border-[#f5f5f5] rounded-[4px] flex items-center justify-center"
        >
          <Link to={`/job/${generateSlug(job?.job_title)}?id=${job?.id}`}>
            <img
              className="h-full"
              src={job?.creator?.avatar}
              alt={job?.title}
            />
          </Link>
        </div>
      )}
    </div>
  );
};

export default JobsCard;
