import React from "react";

export default function MainPageHeader({ title, bgImage }) {
  return (
    <>
      <div
        className="w-full px-12 py-12 flex justify-center items-center bg-center bg-no-repeat bg-cover h-28 sm:h-36 md:h-48 lg:h-60 relative  before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-gradient-to-b before:from-transparent before:to-[#012e54] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:mix-blend-color after:bg-gradient-to-b after:from-[#aaffe7] after:via-[#40a7ff] after:to-[#012e54]"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h1 className="text-base sm:text-xl md:text-3xl lg:text-5xl font-bold text-white font-title text-center m-0 absolute z-10">
          {title}
        </h1>
      </div>
    </>
  );
}
