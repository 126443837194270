import React from 'react'

const Badge = ({ text, w = "100px", h = "30px" }) => {
    return (
        <div
            className={`font-paragarphFont font-bold text-xs text-center flex justify-center items-center 
                tracking-wider uppercase text-main bg-bgmain rounded-[15px] w-[${w}] h-[${h}]`}
        >
            {text}
        </div>
    )
}

export default Badge
