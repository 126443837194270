import { createSlice } from "@reduxjs/toolkit";

const editFormUISlice = createSlice({
  name: "editFormUI",
  initialState: {
    editFormState: false,
  },
  reducers: {
    toggleEditForm: (state, action) => {
      state.editFormState = action.payload;
    },
  },
});

export const { toggleEditForm } = editFormUISlice.actions;

export default editFormUISlice.reducer;
